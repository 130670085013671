<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction, downloadAction, getParams } from '@/command/netTool'
import orderConfig from '../../../orderConfig'
import apiTool from '@/command/apiTool'
import { orderStateList } from '@/utils/textFile'
import { Modal } from 'ant-design-vue'
export default {
  name: 'localProducts',
  data() {
    return {
      ...api.command.getState(),
      courierData: [],
      orderItemListId: null,
    }
  },
  activated() {
    const { status, userTeamId } = this.$route.query
    if (status || userTeamId) {
      this.getList(status, userTeamId)
    }
  },
  mounted() {
    const { status, userTeamId } = this.$route.query
    this.getList(status, userTeamId)
    // 获取收货方式列表
    this.getCourier()
  },
  methods: {
    getList(status, userTeamId) {
      api.command.getList.call(this, {
        url: '/product/order/page',
        current: 1,
        params: { status, userTeamId },
      })
    },
    getCourier() {
      getAction('/api/base/express').then((result) => {
        this.courierData = result.data.map((e) => {
          return {
            ...e,
            value: e.code,
          }
        })
      })
    },
    // 获取选中订单发票详情
    getInvoiceDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/farmOrderInvoice/queryDetailByOrderId?orderId=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    // 获取选中订单详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/api/product/order/detail?id=${id}`)
          .then((result) => {
            resolve({
              ...result.data,
              valCopy:
                result.data.deliveryList && result.data.deliveryList.length > 0 ? result.data.deliveryList[0] : {},
            })
          })
          .catch((e) => reject && reject(e))
      })
    },
    //开票信息
    onInvoice(records) {
      const ext = [
        // 基本信息
        orderConfig.invoices,
        // 接收信息
        orderConfig.invoicesAccept,
      ]
      this.getInvoiceDetailData(records.id).then((e) => {
        orderConfig.open({
          title: '开票信息',
          data: e,
          params: { data: e },
          executeFun: ext,
        })
      })
    },
    // 待发货
    onDelivery(data) {
      this.getDetailData(data.id).then((result) => {
        data = result

        orderConfig.delivery({
          data,
          params: {
            data,
            typeData: {
              courierData: this.courierData,
            },
            type: 'specialty',
          },
          success: ({ data, setHidden }) => {
            if (data.deliveryType == '0') {
              if (data.deliveryCode) {
                data.deliveryCompany = this.courierData.find((e) => e.value == data.deliveryCode).name
              } else {
                this.$message.warning('请选择发货方式！')
                return
              }
            }
            if (data.deliverySn) {
              data.deliverySn = data.deliverySn.replace(/\s*/g, '')
            }
            api.order.delivery.call(this, { url: '/product/order/shipping', params: data }).then(setHidden)
          },
        })
      })
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '收货人',
          type: 'input',
          key: 'buyName',
        },
        {
          name: '电话',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入收货人电话',
        },
        {
          name: '订单时间',
          type: 'rangePicker',
          keys: ['startTime', 'endTime'],
          showTimeNo: true,
        },
      ]
    },
    // 关闭订单事件
    onCloseOrder(records) {
      api.order.close.call(this, {
        url: '/common/order/close',
        params: { id: records.id },
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      orderConfig.refund({
        data: records,
        params: { data: records, childrenId: this.orderItemListId },
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/product/order/refund',
              params: { ...item, itemId: item.id, ...data },
            })
            .then(setHidden)
        },
      })
    },
    getColumnsButtonData({ parent, item, index, childrenId } = {}) {
      if (index == 0) {
        let arr = [
          {
            name: '客户退单',
            display: parent.refundCount !== null && Number(parent.refundCount) > 0,
          },
          {
            name: '收货',
            display: parent.status == '3',
            onClick: () => this.getDetailData(parent.id).then(this.received),
          },
          {
            name: '发货',
            display: (parent.status == '2' || parent.status == '3') && item.returnStatus != '2',
            onClick: () => this.onDelivery(parent),
          },
          {
            name: '开票信息',
            display: parent.invoiceStatus && parent.invoiceStatus != 0,
            onClick: () => this.onInvoice(parent),
          },
          {
            name: '主动退款',
            display:
              item.returnStatus != '2' &&
              Number(parent.status) !== 0 &&
              Number(parent.afterSalesFlag) === 0 &&
              Number(parent.status) !== 6,
            onClick: (item) => {
              this.orderItemListId = childrenId
              this.getDetailData(parent.id).then((e) => this.onRefund({ item, records: e }))
            },
          },
          {
            name: '关闭订单',
            display: parent.status == '0',
            type: 'pop',
            popTitle: '确定关闭订单吗',
            // onClick: () => this.getDetailData(parent.id).then((e) => this.payment(e)),
            onClick: () => {
              api.order.close
                .call(this, {
                  url: '/common/order/close',
                  params: { id: parent.id },
                })
                .then()
            },
          },
        ]
        // if (process.env.NODE_ENV === 'development') {
          return arr.filter((e) => e.display)
        // } else {
        //   const buttonName = apiTool.getButtonName()
        //   return arr.filter((e) => e.display && buttonName.some((el) => e.name.indexOf(el) !== -1))
        // }
      } else {
        // 除了第一个以外的其他子订单 判断是否有退款记录 如果有的话 则不显示主动退款
        if (
          item.returnStatus != '2' &&
          Number(parent.status) !== 0 &&
          Number(parent.afterSalesFlag) === 0 &&
          Number(parent.status) !== 6
        ) {
          return [
            {
              name: '主动退款',
              onClick: (item) => {
                this.orderItemListId = childrenId
                this.getDetailData(parent.id).then((e) => this.onRefund({ item, records: e }))
              },
            },
          ]
        } else {
          return []
        }
      }
    },
    renderColumnButton({ orderItem, item }) {
      return (
        <a
          style={{ marginRight: '10px' }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            item.onClick(orderItem)
          }}
        >
          {item.name}
        </a>
      )
    },

    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.orderSn - b.orderSn,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-number">
                {records.orderItemList.map((e) => {
                  return <div>{e.productQuantity}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            if (records.refundAmount) {
              return (
                <div>
                  <span>{records.payAmount.toFixed(2)}</span>
                  <span>（{records.refundAmount.toFixed(2)} 已退）</span>
                </div>
              )
            } else {
              return <div>{records.payAmount.toFixed(2)}</div>
            }
          },
        },
        {
          dataIndex: 'salesProductPrice',
          title: '分销价',
          align: 'left',
          customRender: (text, records) => {
            return <div>{records.salesProductPrice !== null ? records.salesProductPrice.toFixed(2) : '-'}</div>
          },
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          align: 'left',
          customRender: (text) => (orderStateList.find((e) => e.value == text) || { text: '' }).text,
          onExport: (text) => (orderStateList.find((e) => e.value == text) || { text: '' }).text,
          filterMultiple: true,
          filters: orderStateList,
        },
        {
          dataIndex: 'receiverName',
          title: '收货人',
          align: 'left',
        },
        {
          dataIndex: 'receiverPhone',
          title: '电话',
          align: 'left',
        },
        {
          dataIndex: 'buyName',
          title: '购买人',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'payType',
          title: '支付方式',
          align: 'left',
          customRender: (text) => {
            return { 1: '支付宝', 2: '微信', 3: '农行', 4: '0元付' }[text] || '未支付'
          },
          isId: true,
        },
        {
          dataIndex: 'createTime',
          title: '购买时间',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          customRender: function customRender(text, records) {
            if (records.status != 6) {
              return (
                <div
                  class="actionBox"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  {records.orderItemList.map((e, index) => {
                    // 获取对应button按钮 子订单需要判断是否有退款
                    const data = that.getColumnsButtonData({
                      parent: records,
                      item: e,
                      index,
                      childrenId: e.id,
                    })
                    return (
                      <div class="product-order-item">
                        {data.map((el) => that.renderColumnButton({ orderItem: e, item: el }))}
                      </div>
                    )
                  })}
                </div>
              )
            } else {
              return null
            }
          },
        },
      ]
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },
    // 已关闭
    completed(data) {
      orderConfig.completed({
        data,
        params: {
          data,
          typeData: {
            courierData: this.courierData,
          },
          type: 'specialty',
        },
        success: ({ data, setHidden }) => {
          Modal.confirm({
            title: '提示',
            content: '此操作将删除该订单, 是否继续？',
            onOk: () => {
              api.command.del
                .call(this, {
                  url: `/product/order/delete?id=${data.id}`,
                })
                .then((res) => {
                  setHidden()
                })
            },
            onCancel() {},
          })
        },
      })
    },
    // 确认收货
    received(data) {
      orderConfig.received({
        data,
        params: {
          data,
          typeData: {
            courierData: this.courierData,
          },
          type: 'specialty',
        },
        success: ({ data, setHidden }) => {
          api.order.received.call(this, { url: '/product/order/receiving', params: data }).then(setHidden)
        },
      })
    },
    // 支付
    payment(data) {
      orderConfig.close({
        data,
        params: {
          data,
          typeData: {
            courierData: this.courierData,
          },
          type: 'specialty',
        },
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    onSelectRow(records) {
      // 0->待支付；1->待使用；2->待发货；3->待签收；4->待评价；5->已完成；6->已关闭；
      switch (records.status) {
        case '6':
        case '5':
        case '4':
          this.getDetailData(records.id).then(this.completed)
          break
        case '3':
          this.getDetailData(records.id).then(this.received)
          break
        case '2':
          this.getDetailData(records.id).then(this.onDelivery)
          break
        case '0':
          this.getDetailData(records.id).then(this.payment)
          break
        default:
      }
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          menu: [
            {
              name: '电商模版',
              onClick: (data) => {
                downloadAction(`/api/product/order/productExport${getParams(data)}`, '特产商品.xlsx')
              },
            },
            {
              name: '文旅模版',
              onClick: (data) => {
                downloadAction(`/api/product/order/exportWl${getParams(data)}`, '特产商品.xlsx')
              },
            },
          ],
          type: 'menuButton',
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        rightButton={this.getRightButton()}
        records={this.records}
        onSelectRow={this.onSelectRow}
        filterRightButtonKey={['refresh', 'setting']}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-order-item:nth-child(1)::after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  color: #999999;
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
.table-foot {
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(232, 234, 236, 1);
    span {
      width: 150px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #515a6e;
    }
  }
}

.actionBox {
  .product-order-item {
    &:nth-of-type(2) {
      margin-left: 10px;
    }
    &:nth-child(1) {
      height: 20px;
      &::after {
        display: none;
      }
    }
  }
}
</style>
